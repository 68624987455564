<template>
  <div class="wrapper">
    <div class="content">
      <div class="container">
        <h1>FAQ</h1>
        <div class="question">
          <div class="q-head" id="h_1" @click="open(1)">
            <h2>¿Cuál es el coste del servicio de optimización de compra?</h2>
            <div class="chev">
              <img
                style="height: 30px"
                src="@/assets/images/illustrations/chev-up.svg"
                alt=""
              />
            </div>
          </div>
          <div class="q-body" id="q_1">
            <p>
              Nuestros servicios son gratuitos. Sí, ahorro gratis, ¿te lo
              demostramos?
            </p>
          </div>
        </div>
        <div class="question">
          <div class="q-head" id="h_2" @click="open(2)">
            <h2>¿Cómo podemos ofrecer un servicio gratuito?</h2>
            <div class="chev">
              <img
                style="height: 30px"
                src="@/assets/images/illustrations/chev-up.svg"
                alt=""
              />
            </div>
          </div>
          <div class="q-body" id="q_2">
            <p>
              Como es evidente nuestra empresa tiene que generar ingresos para
              subsistir. Para ello, negociamos con empresas del sector
              energético condiciones para que todas las partes salgan ganando:
              el consumidor ahorra, el nuevo proveedor capta clientes y nosotros
              cobramos al proveedor por los servicios prestados al cliente.
            </p>
          </div>
        </div>
        <div class="question">
          <div class="q-head" id="h_3" @click="open(3)">
            <h2>
              ¿Qué es necesario para optimizar la compra energética de una
              empresa?
            </h2>
            <div class="chev">
              <img
                style="height: 30px"
                src="@/assets/images/illustrations/chev-up.svg"
                alt=""
              />
            </div>
          </div>
          <div class="q-body" id="q_3">
            <p>
              Una sola factura es información suficiente para comenzar el
              estudio. De la factura extraemos información como cual es el
              código CUPS, que nos da acceso a los consumos históricos del
              suministro en cuestión, cuál es el método de compra actual, que
              precios se están pagando, así como otros datos relevantes.
            </p>
          </div>
        </div>
        <div class="question">
          <div class="q-head" id="h_4" @click="open(4)">
            <h2>¿Qué problemas puede ocasionar?</h2>
            <div class="chev">
              <img
                style="height: 30px"
                src="@/assets/images/illustrations/chev-up.svg"
                alt=""
              />
            </div>
          </div>
          <div class="q-body" id="q_4">
            <p>
              El cambio de compañía comercializadora no produce cortes de luz ni
              conflictos con la empresa distribuidora (es común que la empresa
              comercializadora y distribuidora de un usuario pertenezcan a la
              misma matriz). De hecho, el cambio de empresa y nuestra
              intermediación facilitarán enormemente la resolución de
              solicitudes o reclamaciones, para que puedas olvidarte de los
              problemas tan comunes con empresas energéticas.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  methods: {
    open: function (question) {
      document.getElementById("q_" + question).classList.toggle("open");
      document.getElementById("h_" + question).classList.toggle("open");
    },
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wrapper {
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 200px;
  background-image: url(../assets/images/wavy_background_bottom_lay.svg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .content {
    .container {
      width: calc(100% - 80px);
      padding: 15px 40px;
      background-color: $dirty-white;
      h1,
      h2,
      p {
        text-align: left;
      }
      img {
        height: 200px;
      }
      .question {
        background-color: #e0e0e0;
        border-radius: 15px;
        margin-bottom: 20px;
        .q-head {
          background-color: #efefef;
          border-radius: 15px;
          padding: 8px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          h2 {
            margin: 0px;
            text-transform: none;
            font-size: 28px;
          }
          &.open {
            .chev {
              transform: rotate(180deg);
            }
          }
        }
        .q-body {
          padding: 20px;
          display: none;
          p {
            margin: 0px;
          }
          &.open {
            display: block;
          }
        }
      }
    }
  }
}
</style>
